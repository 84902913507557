/* Existing CSS */
.Directions {
  color: #f8f8f8;
  font-family: 'Poppins', sans-serif;
  position: relative;
  z-index: 10;
  background: #00315A;
  padding-top: 80px;
}

.Directions h1 {
  margin-top: 0px;
  font-size: 2em;
  text-align: center;
}

.DirectionsBody {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.DirectionsBody p {
  flex-basis: 30%;
  padding: 50px;
}

.DirectionsBody iframe {
  flex-basis: 60%;
  width: 100%;
  height: 450px;
}

/* Add this media query for screens smaller than 768px */
@media (max-width: 768px) {
  .DirectionsBody {
    flex-direction: column;
  }

  .DirectionsBody p,
  .DirectionsBody iframe {
    flex-basis: auto;
    /* Resetting flex-basis so they take up the needed space */
    width: 90%;
    /* Ensuring they take the full width of the container */

    /* Adjust padding as needed */
  }
}