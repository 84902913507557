.Container {
  background: #00315A;
}

.Vita {
  color: #f8f8f8;
  font-family: 'Poppins', sans-serif;
  position: relative;
  z-index: 10;
  background: #00315A;
  margin: 0px;
  padding: 0px;
}

.Vita h1 {
  margin: 0px;
  font-size: 2em;
  text-align: center;
}

.h2 {
  background: #00315A;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  /* This centers the text vertically */
  justify-content: center;
  /* This centers the text horizontally */
  z-index: 10;
  transform: translateY(-17px);
  margin: 0px;
  color: #fff
}


.scrolldownIndicator {
  position: absolute;
  left: 50%;
  bottom: 10px;
  width: 5px;
  height: 50px;
  background-color: #fff;
  z-index: 1;
  animation: bounce 1.5s ease-in-out infinite;
  border-radius: 10px;
}


@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    transform: translateY(5px);
    opacity: 0.7;
  }
}