/* Slideshow.module.css */
.slideshowContainer {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
    z-index: 1;
}

.imageBox {
    width: 100%;
    /* Ensure the image spans the full width of its container */
    height: 100vh;
    /* Adjust the height to fit the viewport or container */
    object-fit: cover;
    /* Cover the container without distorting the aspect ratio */
    object-position: center;
    /* Center the image within the container */

    /* Adjust the pixel value to control the blur intensity */

}

.blur {
    filter: blur(7px);
}

.stepperBox {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
}



.iconButton {
    position: absolute;
    top: 200px;
    transform: translateY(-50%);
    background-color: rgba(255, 0, 0, 0.7);
    font-size: 2rem;
    z-index: 2;
    /* Ensure the icons are above the images */
}

.iconButton:hover {
    background-color: rgba(255, 255, 255, 1);
}

.leftIcon {
    left: 16px;
}

.rightIcon {
    right: 16px;
}


.textContent {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2;
    color: rgb(255, 255, 255);
}

.uperTitle {
    font-size: 1.5rem;
    opacity: 0;
}

.centeredText {
    font-size: 4rem;
    opacity: 0;
}

.subTitle {
    font-size: 1.5rem;
    opacity: 0;
    margin-top: 20px;
}

.buttonBelow {
    padding: 10px 40px;
    font-size: 16px;
    font-weight: bold;
    margin: 20px;
    background: rgb(255, 255, 255);
    border: 2px solid white;
    border-radius: 20px;
    text-decoration: none;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    color: #000;
    position: relative;
    min-width: 180px;
    box-sizing: border-box;
    opacity: 0;
}

.buttonBelow:hover {
    background-color: white;
    color: #000;
}

.buttonBelow span {
    transition: transform 0.3s ease;
}

.buttonBelow:hover span {
    transform: translateX(-10px);
}

.buttonBelow:after {
    content: ' \290D';
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.3s ease, right 0.3s ease;
    opacity: 0;
}

.buttonBelow:hover:after {
    right: 30px;
    opacity: 1;
}


@keyframes fade-in {
    from {
        opacity: 0;
        transform: translate(0, 100px);
    }

    to {
        opacity: 1;
        transform: translate(0, 0);
    }
}

.animateonvisibleuperTitle {
    animation: fade-in 2s forwards;
}

.animateonvisiblecenteredText {
    animation: fade-in 2s 0.3s forwards;
}

.animateonvisiblesubTitle {
    animation: fade-in 2s 0.6s forwards;
}

.animateonvisiblebuttonBelow {
    animation: fade-in 2s 0s forwards;
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translate(0, 0);
    }

    to {
        opacity: 0;
        transform: translate(0, 100px);
    }
}

.animateOutUperTitle {
    animation: fadeOut 1s 0.6s forwards;
    opacity: 1;
}

.animateOutCenteredText {
    animation: fadeOut 1s 0.4s forwards;
    opacity: 1;
}

.animateOutSubTitle {
    animation: fadeOut 1s 0.2s forwards;
    opacity: 1;
}

.animateOutButtonBelow {
    animation: fadeOut 1s forwards;
    opacity: 1;
}

.PhotoBy {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
    color: rgb(0, 0, 0);
    font-size: 0.8rem;
    opacity: 1;
}