.pricingContainer {
  color: #f8f8f8;
  font-family: 'Poppins', sans-serif;
  position: relative;
  z-index: 10;
  background: #00315A;
  padding: 80px;
}

.pricingContainer TableCell {
  color: #f8f8f8;
}

h1 {
  padding-top: 40px;
  margin-top: 0px;
  font-size: 2em;
  text-align: center;
}

.pricingContainer .TableCell {
  color: #fff !important;
  /* Ensure this is white */
}

.pricingContainer .MuiTable-root .MuiTableCell-root {
  color: #fff !important;
  /* Ensure this is the color code you want */
}

.disclaimer {
  padding-top: 40px;
  font-size: small;
}

@media (max-width: 768px) {

  .pricingContainer {
    padding: 30px;
  }


}