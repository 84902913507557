.slideshowContainer {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
    z-index: 1;
}

.imageBox {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center;

}

.blur {
    filter: blur(7px);
}


.leftIcon {
    left: 16px;
}

.rightIcon {
    right: 16px;
}


.textContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
    z-index: 2;
    color: rgb(255, 255, 255);
}


.uperTitle {
    font-size: 1.5rem;
    opacity: 0;
}

.centeredText {
    font-size: 4rem;
    opacity: 0;
}

.subTitle {
    font-size: 1.5rem;
    opacity: 0;
    margin-top: 20px;
}

.icons {
    width: 200px;
    height: 200px;
    opacity: 0;

}



@keyframes fade-in {
    from {
        opacity: 0;
        transform: translate(0, 100px);
    }

    to {
        opacity: 1;
        transform: translate(0, 0);
    }
}

.animateonvisibleuperTitle {
    animation: fade-in 2s forwards;
}

.animateonvisiblecenteredText {
    animation: fade-in 2s 0.3s forwards;
}

.animateonvisiblesubTitle {
    animation: fade-in 2s 0.6s forwards;
}

.animateonvisiblebuttonBelow {
    animation: fade-in 2s 0.9s forwards;
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translate(0, 0);
    }

    to {
        opacity: 0;
        transform: translate(0, 100px);
    }
}

.animateOutUperTitle {
    animation: fadeOut 1s 0.6s forwards;
    opacity: 1;
}

.animateOutCenteredText {
    animation: fadeOut 1s 0.4s forwards;
    opacity: 1;
}

.animateOutSubTitle {
    animation: fadeOut 1s 0.2s forwards;
    opacity: 1;
}

.animateOutButtonBelow {
    animation: fadeOut 1s forwards;
    opacity: 1;
}

.PhotoBy {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
    color: rgb(255, 255, 255);
    font-size: 0.8rem;
    opacity: 1;
}



.vacationBox {
    position: absolute;
    bottom: 15%;
    right: 20%;
    background-color: red;
    padding: 16px;
    transform: rotate(10deg);
    max-Width: 500px;
    min-Width: 200px;
    color: white;
    box-Shadow: 10px 10px 20px rgba(0, 0, 0, 1);
    border-Radius: 0;
}


@media (max-width: 768px) {

    .uperTitle,
    .subTitle {
        font-size: 1.3em;
    }

    .centeredText {
        font-size: 2.7em;
    }

    .vacationBox {
        bottom: 10%;
        right: 5%;
        left: 5%;
    }

    .icons {
        width: 130px;
        height: 130px;
    }

}